import { useQuery } from '@apollo/client'
import RefreshButton from 'Components/Button/RefreshButton'
import ImportUsersForm from 'Components/Form/ImportUsersForm'
import Loader from 'Components/Loader'
import PageHeader from 'Components/PageHeader'
import SelectHasOfferFilterInput from 'Components/SelectHasOfferFilterInput'
import UsersExportButtons from 'Components/User/UsersExportButtons'
import UsersPaginatedTable, { UserSortBy } from 'Components/User/UsersPaginatedTable'
import UserUpdateCacheReceiptButton from 'Components/UserUpdateCacheReceiptButton'
import AddUserButton from 'Forms/User/AddUserButton'
import { readFragment } from 'gql.tada'
import usePagination from 'hooks/usePagination'
import { observer } from 'mobx-react-lite'
import { useGetLightOrganism } from 'models'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { Button, Form, Grid, Icon, Segment, Statistic } from 'semantic-ui-react'
import { HasOfferFilter, Permission, UserRoleId } from 'services/api/graphql'
import { useCurrentUser, useStore } from 'stores'
import UserRolesContext, { userRoles } from 'stores/Contexts/UserRolesContext'
import { notifyError } from 'tools/toaster'

import { GetUsersQuery, UserFragment } from './fragments'

export interface UserRoleIconsProps {
  roleIds: UserRoleId[]
}

const UsersViewBase = observer(() => {
  const currentUser = useCurrentUser()
  const { organismId } = useStore()
  const intl = useIntl()

  const { activePage, onPageChange, paginationParams, pageSize } = usePagination()

  const { organism } = useGetLightOrganism(organismId || '', {
    skip: !organismId,
  })

  const [fanFilter, setFanFilter] = useState(false)
  const toggleFanFilter = useCallback((_, { checked }) => {
    setFanFilter(checked)
    onPageChange(1)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    setFanFilter(false)
  }, [organismId])

  const [offerFilter, setOfferFilter] = useState<HasOfferFilter | null>(null)
  const [roleFilter, setRoleFilter] = useState<UserRoleId | undefined>(undefined)

  const canSeeFans = useMemo(
    () => currentUser.can(Permission.FansRead, organismId || undefined, true),
    [currentUser, organismId],
  )
  const canSeeLicensedUsers = useMemo(
    () => organism && organism.license && organism.license.enabled && currentUser.can(Permission.UserRead, organism.id),
    [currentUser, organism],
  )
  const canSeeOffers = useMemo(() => currentUser.can(Permission.PurchasableOfferRead, undefined, true), [currentUser])
  const canExportUsers = useMemo(() => currentUser.can(Permission.UsersExport, undefined, true), [currentUser])
  const fanView = useMemo(() => fanFilter && !organismId, [fanFilter, organismId])
  const licensedView = useMemo(() => fanFilter && !!organismId, [fanFilter, organismId])
  const offerView = useMemo<HasOfferFilter | null>(() => (organismId ? null : offerFilter), [offerFilter, organismId])

  const [searchQuery, setSearchQuery] = useState<string | null>()
  const [sortBy, setSortBy] = useState<UserSortBy | undefined>()
  const {
    data: usersQuery,
    error,
    loading,
    refetch,
  } = useQuery(GetUsersQuery, {
    variables: {
      filters: {
        organismId: licensedView ? undefined : organismId,
        fans: fanView || licensedView,
        licensedForOrganismId: licensedView ? organismId : undefined,
        hasOffer: offerView ? offerView : null,
        search: searchQuery,
        roleIds: roleFilter ? [roleFilter] : undefined,
      },
      ...paginationParams,
      sortBy,
    },
    fetchPolicy: 'cache-and-network',
  })
  const users = usersQuery?.users?.users.map(user => readFragment(UserFragment, user))
  const totalCount = usersQuery?.users?.totalCount

  const totalPages = useMemo(() => {
    if (!totalCount) return 0
    return Math.ceil(totalCount / pageSize)
  }, [pageSize, totalCount])

  useEffect(() => {
    if (error) notifyError(<FormattedMessage id="error.general" />, error)
  }, [error])

  const selectableRoles = userRoles.filter(roleId => {
    if (roleId === UserRoleId.Anonymous) return false
    // Wildmoka role can't be selected if there is already a wildmoka user
    if (
      users &&
      users.findIndex(userItem => (userItem.roleIds && userItem.roleIds.includes(UserRoleId.Wildmoka)) || false) > -1
    ) {
      return roleId !== UserRoleId.Wildmoka
    }
    return true
  })

  const [search, setSearch] = useState<string | null>()
  const handleSearch = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    setSearchQuery(search)
  }

  const [importModalOpen, setImportModalOpen] = useState(false)

  const onAfterImport = useCallback(() => {
    setImportModalOpen(false)
  }, [])

  const actionsOnUsers = useMemo(
    () =>
      currentUser.can(Permission.UserUpdate, undefined, true) ||
      (!!organismId && currentUser.can(Permission.UserUpdate, organismId) && !fanFilter),
    [currentUser, organismId, fanFilter],
  )

  return (
    <Loader loading={loading}>
      <Grid padded>
        <UserRolesContext.Provider value={selectableRoles}>
          <Grid.Column>
            <PageHeader
              title={
                <FormattedMessage
                  id={`users.${fanView ? 'header_fans' : licensedView ? 'header_licensed' : 'header'}`}
                />
              }
            >
              {fanFilter && currentUser.can(Permission.UserCreate) && (
                <Button primary onClick={() => setImportModalOpen(true)}>
                  <FormattedMessage id="users.import_fans" />
                </Button>
              )}
              {canExportUsers && <UsersExportButtons />}
              {currentUser.can(Permission.UpdateCacheReceipt) && <UserUpdateCacheReceiptButton />}
              <RefreshButton onClick={() => refetch()} loading={loading} />
              {currentUser.can(Permission.UserCreate) && (
                <AddUserButton
                  onDone={() => refetch()}
                  render={onClick => <Button circular icon="add" color="red" onClick={onClick} />}
                />
              )}
            </PageHeader>

            <Statistic.Group>
              {!!totalCount && (
                <Statistic color="blue">
                  <Statistic.Value text>
                    <Icon name="user" /> {totalCount}
                  </Statistic.Value>
                  <Statistic.Label>
                    <FormattedMessage id="users.totalCount" />
                  </Statistic.Label>
                </Statistic>
              )}

              {!!organism?.fansCount && (
                <Statistic color="red">
                  <Statistic.Value text>
                    <Icon name="heart" /> {organism.fansCount}
                  </Statistic.Value>
                  <Statistic.Label>
                    <FormattedMessage id="organism.fansCount" />
                  </Statistic.Label>
                </Statistic>
              )}
            </Statistic.Group>

            <Segment basic>
              <Form>
                <Form.Group inline>
                  {((canSeeFans && !organismId) || canSeeLicensedUsers) && (
                    <Form.Checkbox
                      toggle
                      onChange={toggleFanFilter}
                      label={intl.formatMessage({
                        id: organismId ? 'users.see_licensed' : 'users.see_fans',
                      })}
                    />
                  )}
                  {canSeeOffers && !organismId && (
                    <Form.Field>
                      <span>
                        <FormattedMessage id="users.see_offers" />
                      </span>
                      <SelectHasOfferFilterInput
                        value={offerFilter}
                        onChange={setOfferFilter}
                      ></SelectHasOfferFilterInput>
                    </Form.Field>
                  )}
                </Form.Group>
              </Form>
            </Segment>

            <div className="my-5">
              <Form onSubmit={handleSearch}>
                <Form.Group>
                  <Form.Dropdown
                    label={intl.formatMessage({ id: 'users.roles' })}
                    selection
                    clearable
                    options={Object.values(UserRoleId).map(role => ({
                      text: intl.formatMessage({ id: `userRole.${role}` }),
                      value: role,
                    }))}
                    onChange={(_, { value }) => setRoleFilter(value as UserRoleId)}
                  />
                  <Form.Input
                    label={`${intl.formatMessage({ id: 'users.search' })}:`}
                    placeholder={intl.formatMessage({
                      id: 'users.searchPlaceholder',
                    })}
                    onChange={e => setSearch(e.target.value)}
                  />
                  <Form.Field>
                    <Form.Button primary type="submit" className="submitSearch">
                      <FormattedMessage id="users.search" />
                    </Form.Button>
                  </Form.Field>
                </Form.Group>
              </Form>
            </div>

            {users && (
              <UsersPaginatedTable
                users={users}
                sortBy={sortBy}
                onSort={sort => setSortBy(sort)}
                totalPages={totalPages}
                activePage={activePage}
                onPageChange={onPageChange}
                withActions={actionsOnUsers}
                onUserDeleted={refetch}
              />
            )}
          </Grid.Column>
        </UserRolesContext.Provider>
      </Grid>
      <ImportUsersForm open={importModalOpen} onSuccess={onAfterImport} onCancel={() => setImportModalOpen(false)} />
    </Loader>
  )
})

export default UsersViewBase
